import React, { useState, useEffect } from "react";
import styles from "./header-container.styles.css";
import { m } from "kremling";
import { getColumnWidthPx } from "../cp-table.helper";

function HeaderContainerBase({
  filterControl,
  HeaderComponent,
  columnSchema,
  columnWidth,
  draggable,
  sticky,
  isDragTarget,
  dragStyle,
  onDragStart,
  onDragOver,
  onDrop,
  onDragEnd,
  onDrag,
  onWidthChange,
  canChangeWidth,
  usingSelections,
}) {
  const [resizeStartPos, setResizeStartPos] = useState();
  const [resizeWidth, setResizeWidth] = useState(); // The width while actively resizing

  const defaultWidth = getColumnWidthPx(columnSchema.width || "md");
  const currentWidth = getColumnWidthPx(columnWidth) || defaultWidth;
  const columnId = columnSchema.id;

  useEffect(() => {
    if (!!resizeStartPos) {
      const minWidth = defaultWidth / 2;
      let override = 0;

      const onMouseMove = (e) => {
        const newWidth = currentWidth + (e.screenX - resizeStartPos);
        if (newWidth >= minWidth) {
          override = newWidth;
          setResizeWidth(newWidth);
        }
      };

      const onMouseUp = () => {
        onWidthChange?.(columnId, override);
        setResizeStartPos(null);
      };

      document.addEventListener("mousemove", onMouseMove);
      document.body.addEventListener("mouseup", onMouseUp);
      return () => {
        document.removeEventListener("mousemove", onMouseMove);
        document.body.removeEventListener("mouseup", onMouseUp);
      };
    }
  }, [resizeStartPos, defaultWidth, currentWidth, onWidthChange, columnId]);

  function isFilterApplied() {
    if (!filterControl) return false;
    const filter = filterControl.filters[columnSchema.id];
    const sort = filterControl.sortData[columnSchema.id];
    return !!filter || !!sort;
  }

  const canDrag = draggable && !sticky && !resizeStartPos;

  return (
    <th
      style={{
        width: !!resizeStartPos
          ? `${resizeWidth}px`
          : `${currentWidth / 10}rem`,
        left: sticky ? (usingSelections ? `4rem` : "0") : undefined,
      }}
      draggable={canDrag}
      className={m(styles.draggable, canDrag)
        .m(styles.stickyHeader, !!columnSchema.sticky)
        .m(dragStyle, isDragTarget)
        .m(styles.nonStickyColumn, !sticky)
        .m(styles.filtersApplied, isFilterApplied())}
      onDrag={onDrag}
      onDragStart={(e) => {
        if (sticky) return;
        e.dataTransfer.effectAllowed = "move";
        onDragStart(e, columnSchema.id);
      }}
      onDragOver={(e) => {
        e.preventDefault();
        if (!sticky && !isDragTarget) {
          onDragOver(e, columnSchema.id);
        }
      }}
      onDrop={onDrop}
      onDragEnd={onDragEnd}
    >
      <div
        className={`flex items-center ${
          columnSchema.header?.props?.align === "right" ? "justify-end" : ""
        }`}
      >
        <HeaderComponent
          columnSchema={columnSchema}
          filterControl={filterControl}
        />
      </div>
      {canChangeWidth && (
        <div
          className={styles.resizeHandle}
          onMouseDown={(e) => {
            e.preventDefault();
            setResizeStartPos(e.screenX);
            setResizeWidth(currentWidth);
          }}
        />
      )}
    </th>
  );
}
export const HeaderContainer = React.memo(HeaderContainerBase);
