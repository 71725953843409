// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".header-container-styles__stickyHeader--uHuCR {\n  background: var(--cp-color-well-l1-bg);\n  position: -webkit-sticky;\n  position: sticky;\n  z-index: 2;\n}\n\n.header-container-styles__nonStickyColumn--j6vJ9 {\n  position: relative;\n}\n\n.header-container-styles__resizeHandle--D4mte {\n  position: absolute;\n  top: 0;\n  right: 0;\n  cursor: col-resize;\n  width: 1.6rem;\n  height: 100%;\n}\n\n.header-container-styles__filtersApplied--xEJIa {\n  background-color: var(--cp-color-table-header-active-bg);\n}\n\n.header-container-styles__draggable--cal\\+w {\n  cursor: grab;\n}\n\n.header-container-styles__selectionCount--k7pZD {\n  font-weight: normal;\n  font-size: 1.2rem;\n  margin-left: 1.6rem;\n  font-style: italic;\n}\n", "",{"version":3,"sources":["webpack://src/components/cp-table/headers/header-container.styles.css"],"names":[],"mappings":"AAAA;EACE,sCAAsC;EACtC,wBAAgB;EAAhB,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,QAAQ;EACR,kBAAkB;EAClB,aAAa;EACb,YAAY;AACd;;AAEA;EACE,wDAAwD;AAC1D;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,iBAAiB;EACjB,mBAAmB;EACnB,kBAAkB;AACpB","sourcesContent":[".stickyHeader {\n  background: var(--cp-color-well-l1-bg);\n  position: sticky;\n  z-index: 2;\n}\n\n.nonStickyColumn {\n  position: relative;\n}\n\n.resizeHandle {\n  position: absolute;\n  top: 0;\n  right: 0;\n  cursor: col-resize;\n  width: 1.6rem;\n  height: 100%;\n}\n\n.filtersApplied {\n  background-color: var(--cp-color-table-header-active-bg);\n}\n\n.draggable {\n  cursor: grab;\n}\n\n.selectionCount {\n  font-weight: normal;\n  font-size: 1.2rem;\n  margin-left: 1.6rem;\n  font-style: italic;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"stickyHeader": "header-container-styles__stickyHeader--uHuCR",
	"nonStickyColumn": "header-container-styles__nonStickyColumn--j6vJ9",
	"resizeHandle": "header-container-styles__resizeHandle--D4mte",
	"filtersApplied": "header-container-styles__filtersApplied--xEJIa",
	"draggable": "header-container-styles__draggable--cal+w",
	"selectionCount": "header-container-styles__selectionCount--k7pZD"
};
export default ___CSS_LOADER_EXPORT___;
