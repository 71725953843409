import React, {
  useRef,
  useMemo,
  useCallback,
  useState,
  ReactNode,
  DragEventHandler,
} from "react";
import { a, m } from "kremling";
import styles from "./cp-table.styles.css";
import { TableHeaders } from "./table-headers.component";
import { TableBody } from "./table-body.component";
import { sortColumns } from "./cp-table.helper";
import { useEdgeScroll } from "./hooks/use-edge-scroll.hook";
import { useTableSelection } from "./hooks/use-table-selection.hook";
import { useBasicTableSelection } from "./hooks/use-basic-table-selection.hook";
import { useTableFilters } from "./hooks/use-table-filters.hook";
import { useTableSchema } from "./hooks/use-table-schema.hook";
import {
  BoolCell,
  CurrencyCell,
  DateCell,
  TextCell,
  ListCell,
  LinkCell,
} from "./cells";
import {
  InputFilter,
  SortFilter,
  DateFilter,
  ConditionalSelectFilter,
  SelectFilter,
  BoolFilter,
} from "./filters";
import { isEmpty } from "lodash";
import { CpTableFooter, CpTableActionBar } from "./sub-components";

export function CpTable({
  className,
  columnOrder,
  columnWidths,
  data,
  disabledResourceIds,
  filterControl,
  fullWidth,
  onColumnOrderChange,
  onColumnWidthsChange,
  onScroll,
  renderContextMenu,
  renderEmptyState,
  renderFooterRow,
  showLoader,
  schema,
  selection,
  visibleColumns,
}: CpTableProps) {
  const [, setTableContainer] = useState<HTMLDivElement>();
  const tableContainerRef = useRef<HTMLDivElement>();

  const orderedColumns = useMemo(() => {
    const cols = isEmpty(visibleColumns) ? Object.keys(schema) : visibleColumns;
    return sortColumns(schema, cols, columnOrder || []);
  }, [columnOrder, schema, visibleColumns]);

  const { runEdgeScroller, stopEdgeScroller } = useEdgeScroll({
    scrollContainerRef: tableContainerRef,
    leftScrollAt: 280,
    rightScrollAt: 90,
    scrollSpeed: 2,
  });

  const onDrag = useCallback(
    (e: DragEventHandler) => {
      runEdgeScroller(e);
    },
    [runEdgeScroller],
  );

  const onDragEnd = useCallback(() => {
    stopEdgeScroller();
  }, [stopEdgeScroller]);

  const setContainerRef = useCallback((node: HTMLDivElement) => {
    // Using state and a ref so that once we have a ref to the container we trigger an update so that the virtualizer knows of the ref and can use it.
    setTableContainer(node);
    tableContainerRef.current = node;
  }, []);

  return (
    <div
      ref={setContainerRef}
      className={a(className).a(styles.tableContainer)}
      onScroll={onScroll}
    >
      <table
        className={m(styles.tableWithEmptyState, renderEmptyState)
          .a(styles.table)
          .m(styles.tableWithFooter, !!renderFooterRow)
          .m("cp-table--full-width", fullWidth)}
      >
        <TableHeaders
          columnWidths={columnWidths}
          filterControl={filterControl}
          onColumnOrderChange={onColumnOrderChange}
          onColumnWidthsChange={onColumnWidthsChange}
          onDrag={onDrag}
          onDragEnd={onDragEnd}
          orderedColumns={orderedColumns}
          schema={schema}
          selection={selection}
          verticalBorders={!!onColumnWidthsChange}
        />
        <TableBody
          data={data}
          disabledResourceIds={disabledResourceIds}
          orderedColumns={orderedColumns}
          renderContextMenu={renderContextMenu}
          renderEmptyState={renderEmptyState}
          showLoader={showLoader}
          schema={schema}
          selection={selection}
          tableContainerRef={tableContainerRef}
          verticalBorders={!!onColumnWidthsChange}
        />
        {renderFooterRow && (
          <tfoot>
            <tr className={styles.footerRow}>{renderFooterRow()}</tr>
          </tfoot>
        )}
      </table>
    </div>
  );
}

// Hooks
CpTable.useSchema = useTableSchema;
CpTable.useSelection = useTableSelection;
CpTable.useBasicSelection = useBasicTableSelection;
CpTable.useFilters = useTableFilters;

// Cells
CpTable.BoolCell = BoolCell;
CpTable.CurrencyCell = CurrencyCell;
CpTable.TextCell = TextCell;
CpTable.DateCell = DateCell;
CpTable.ListCell = ListCell;
CpTable.LinkCell = LinkCell;

// Filters
CpTable.InputFilter = InputFilter;
CpTable.SortFilter = SortFilter;
CpTable.DateFilter = DateFilter;
CpTable.ConditionalSelectFilter = ConditionalSelectFilter;
CpTable.SelectFilter = SelectFilter;
CpTable.BoolFilter = BoolFilter;

// Sub-components
CpTable.Footer = CpTableFooter;
CpTable.ActionBar = CpTableActionBar;

CpTable.defaultProps = {
  fullWidth: false,
};

interface CpTableProps {
  /**
   * An array containing the resources to be displayed in the table. Each item must have an `id` property.
   */
  data: {
    id: string | number;
    [key: string]: any;
  }[];

  /**
   * An object returned from `CpTable.useSchema` outlining info and configuration details for the table columns. Check out the [Schema page](/?path=/docs/components-cptable-schema--docs) for more details.
   */
  schema: Record<string, any>;

  /** className applied to the container div around the table element. */
  className?: string;

  /** An array of column IDs in the order they should be displayed. */
  columnOrder?: string[];

  /** A map of column IDs to the width. The width value can be a size string (sm, md, lg) or a number representing a pixel value. */
  columnWidths?: Record<string, string | number>;

  /** An array of resource ids that are considered disabled */
  disabledResourceIds?: string[];

  /**
   * Filter controller object returned from `CpTable.useFilters`. If provided, the columns will default to using the filter header, otherwise it will use a basic text header.
   */
  filterControl?: Record<string, any>;

  /** If true the column widths will attempt to fill the width of the table container. If you're wanting to support table resizing and dynamic columns then you probably will want this set to false. Otherwise if your table widths and column counts are fixed then you'll most likely want this set to true. */
  fullWidth?: boolean;

  /**
   * Called when the user has changed the column order.
   * @param {array} columnIds - Array of column IDs in the new order.
   */
  onColumnOrderChange?: (columnIds: string[]) => void;

  /**
   * Called when the user has changed the column widths.
   * @param {object} columnWidths - Map of column IDs to the new widths.
   */
  onColumnWidthsChange?: (
    columnWidths: Record<string, string | number>,
  ) => void;

  /** Callback passed to the `onScroll` event of the containing div. */
  onScroll?: React.UIEventHandler<HTMLDivElement>;

  /**
   * If provided, `onContextMenu` will be overridden and will display a `CpContextDropdown` with this method being passed to the `renderContent` prop.
   */
  renderContextMenu?: () => ReactNode;

  /** If provided, the table will only render the headers and the body will be replaced with the result of this function.  */
  renderEmptyState?: () => ReactNode;

  /** If provided, the table will render a footer row with the result of this function in a `<tr>` tag. */
  renderFooterRow?: () => ReactNode;

  /** If true then the table body will show a loader. */
  showLoader?: boolean;

  /**
   * Selection object returned from `CpTable.useSelection`. If provided, the table will render a sticky selection column.
   */
  selection?: Record<string, any>;

  /** An array of columns IDs that should be visible. If empty or falsey then all columns will be visible. */
  visibleColumns?: string[];
}
