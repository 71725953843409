import React from "react";
import { a, m } from "kremling";
import { CpDropdown, CpIcon } from "@components";
import styles from "./list-cell.styles.css";

export function ListCell({ value: list, icon, isDisabled }) {
  if (list.length <= 2) {
    return (
      <span className={m("cp-table-cell-disabled", isDisabled)}>
        {list.join(", ")}
      </span>
    );
  } else if (list?.length > 2) {
    return (
      <div className="flex">
        <span
          className={a("cp-ellipsis").m("cp-table-cell-disabled", isDisabled)}
        >
          {list[0]}
        </span>
        <CpDropdown
          disabled={isDisabled}
          contentWidth="md"
          contentHeight={400}
          allowContentClicks
          appendTo="parent"
          renderWhenClosed={false}
          renderTrigger={({ toggle }) => (
            <button
              className={`${styles.moreButton} cp-body-sm shrink-0`}
              onClick={toggle}
              disabled={isDisabled}
            >
              {`+${list.length - 1} more`}
            </button>
          )}
          renderContent={() => (
            <div className={styles.dropdownContent}>
              {list.map((item, i) => {
                if (i <= 0) return null;
                return (
                  <div key={i} className="cp-p-8 cp-flex-center">
                    {icon && <CpIcon name={icon} className="cp-mr-8" />}
                    <div className="cp-ellipsis">{item}</div>
                  </div>
                );
              })}
            </div>
          )}
        />
      </div>
    );
  }
}
