import { CpTable } from "@components";

export const columnWidthsPx = {
  sm: 110,
  md: 200,
  lg: 300,
};

// Returns a number representing the width of the column in pixels
export function getColumnWidthPx(widthValue) {
  return typeof widthValue === "number"
    ? widthValue
    : columnWidthsPx[widthValue];
}

// Returns a copy of colsToSort sorted by the columnOrder
export function sortColumns(schema, colsToSort, columnOrder) {
  return [...colsToSort].sort((a, b) => {
    if (schema[a]?.sticky === "left" && schema[b]?.sticky !== "left") return -1;
    if (schema[a]?.sticky !== "left" && schema[b]?.sticky === "left") return 1;

    let indexA = columnOrder.indexOf(a);
    let indexB = columnOrder.indexOf(b);
    // If index cannot be found then push these columns to the end of the list
    if (indexA === -1) indexA = Infinity;
    if (indexB === -1) indexB = Infinity;

    return indexA - indexB;
  });
}

function getCellComponentFromDataType(dataType) {
  switch (dataType) {
    case "string":
    case "number":
      return CpTable.TextCell;
    case "bool":
      return CpTable.BoolCell;
    case "date_iso":
    case "date_unix_ms":
      return CpTable.DateCell;
    case "array":
      return CpTable.ListCell;
    default:
      return null;
  }
}

function getFilterComponentFromDataType(dataType) {
  switch (dataType) {
    case "bool":
      return CpTable.BoolFilter;
    case "date_iso":
    case "date_unix_ms":
      return CpTable.DateFilter;
    default:
      return CpTable.InputFilter;
  }
}

// Takes a schema defined by the consumer and returns a fully formed schema with default values and other useful info
export function formatSchema(schema) {
  const newSchema = {};
  for (let columnId in schema) {
    const columnSchema = schema[columnId];
    newSchema[columnId] = {
      ...columnSchema,
      id: columnId,
      cell: {
        component: getCellComponentFromDataType(columnSchema.dataType),
        ...columnSchema.cell,
      },
      filter: columnSchema.filter
        ? {
            component: getFilterComponentFromDataType(columnSchema.dataType),
            fieldId: columnSchema.fieldId,
            ...columnSchema.filter,
          }
        : false,
      sort: columnSchema.sort
        ? {
            fieldId: columnSchema.fieldId,
            ...columnSchema.sort,
          }
        : false,
    };
  }
  return newSchema;
}
