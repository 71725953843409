import React, { useState, useCallback, useRef } from "react";
import styles from "./table-headers.styles.css";
import { m } from "kremling";
import { isEqual } from "lodash";

import { SelectAllHeader } from "./headers/select-all-header.component";
import { HeaderContainer } from "./headers/header-container.component";
import { FilterHeader } from "./headers/filter-header.component";
import { TextHeader } from "./headers/text-header.component";

export function TableHeaders({
  columnWidths,
  filterControl,
  onColumnOrderChange,
  onColumnWidthsChange,
  onDrag,
  onDragEnd,
  orderedColumns,
  schema,
  selection,
  verticalBorders,
}) {
  const [dragStartId, setDragStartId] = useState(null);
  const [dragTargetId, setDragTargetId] = useState(null);
  const [dragStartPos, setDragStartPos] = useState(0);
  const [dragStyle, setDragStyle] = useState("");

  const onHeaderDrag = useCallback((e) => onDrag(e), [onDrag]);

  const onDragStart = useCallback((e, columnId) => {
    setDragStartId(columnId);
    setDragStartPos(e.clientX);
  }, []);

  const onDragOver = useCallback(
    (e, targetColumnId) => {
      setDragStyle(
        e.clientX - dragStartPos > 0 ? styles.dragRight : styles.dragLeft,
      );
      setDragTargetId(targetColumnId);
    },
    [dragStartPos],
  );

  const onDrop = useCallback(() => {
    const cols = [...orderedColumns];
    const fromIndex = cols.indexOf(dragStartId);
    const toIndex = cols.indexOf(dragTargetId);
    cols.splice(fromIndex, 1);
    cols.splice(toIndex, 0, dragStartId);
    if (!isEqual(cols, orderedColumns)) {
      onColumnOrderChange(cols);
    }
  }, [orderedColumns, dragStartId, dragTargetId, onColumnOrderChange]);

  const onHeaderDragEnd = useCallback(() => {
    setDragStartId(null);
    setDragTargetId(null);
    setDragStyle("");
    onDragEnd?.();
  }, [onDragEnd]);

  const onColumnWidthsChangeRef = useRef();
  onColumnWidthsChangeRef.current = onColumnWidthsChange;
  const onWidthChange = useCallback(
    (columnId, width) => {
      onColumnWidthsChangeRef.current?.({
        ...columnWidths,
        [columnId]: width,
      });
    },
    [columnWidths],
  );

  function getDefaultHeader(columnSchema) {
    return filterControl &&
      (columnSchema.filter !== false || columnSchema.sort !== false)
      ? FilterHeader
      : TextHeader;
  }

  return (
    <thead
      className={m(styles.isDragging, !!dragStartId)
        .a(styles.tableHeader)
        .m(styles.verticalBorder, verticalBorders)}
    >
      <tr>
        {selection && (
          <th className={styles.selectCell}>
            <SelectAllHeader selection={selection} />
          </th>
        )}
        {orderedColumns.map((columnId) => {
          const columnSchema = schema[columnId];
          if (!columnSchema) return null;
          const HeaderComponent =
            columnSchema.header?.component || getDefaultHeader(columnSchema);
          const columnWidth = columnWidths?.[columnId];

          return (
            <HeaderContainer
              key={columnId}
              filterControl={filterControl}
              HeaderComponent={HeaderComponent}
              columnSchema={columnSchema}
              columnWidth={columnWidth}
              draggable={!!onColumnOrderChange}
              sticky={columnSchema.sticky}
              isDragTarget={columnId === dragTargetId}
              dragStyle={dragStyle}
              onDragStart={onDragStart}
              onDragOver={onDragOver}
              onDrop={onDrop}
              onDragEnd={onHeaderDragEnd}
              onDrag={onHeaderDrag}
              onWidthChange={onWidthChange}
              canChangeWidth={!!onColumnWidthsChange}
              usingSelections={!!selection}
            />
          );
        })}
      </tr>
    </thead>
  );
}
