import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useVirtualizer } from "@tanstack/react-virtual";
import { m } from "kremling";
import { isEmpty, isNumber, isBoolean } from "lodash";
import { CpContextDropdown, CpLoader } from "@components";
import { SelectCell, EmptyCell } from "./cells";
import styles from "./table-body.styles.css";

export function TableBody({
  disabledResourceIds,
  tableContainerRef,
  orderedColumns,
  schema,
  data,
  selection,
  renderContextMenu,
  renderEmptyState,
  showLoader,
  verticalBorders,
}) {
  const contextMenuRef = useRef();
  const rowVirtualizer = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: data.length,
    // 48px is the fixed height chosen by UX.
    estimateSize: () => 48,
    overscan: 20,
  });
  const [lastSelectedIndex, setLastSelectedIndex] = useState(null);

  const items = rowVirtualizer.getVirtualItems();
  const paddingTop = items.length > 0 ? items[0].start : 0;
  const paddingBottom =
    items.length > 0
      ? rowVirtualizer.getTotalSize() - items[items.length - 1].end
      : 0;

  const [contextMenuResource, setContextMenuResource] = useState();
  // Separate ref that only updates on click so the rendered menu will always have an id even when the dropdown is closed.
  const clickedResourceRef = useRef();
  const hasContextMenu = !!renderContextMenu;
  const openContextMenu = useMemo(() => {
    if (!hasContextMenu) return null;
    return (e, resource) => {
      if (contextMenuRef.current) {
        clickedResourceRef.current = resource;
        contextMenuRef.current.open({ top: e.pageY, left: e.pageX });
        setContextMenuResource(resource);
      }
    };
  }, [hasContextMenu]);

  const onSelect = useCallback(
    (evt, resource, resourceIndex) => {
      if (
        evt.nativeEvent.shiftKey &&
        lastSelectedIndex !== null &&
        lastSelectedIndex !== resourceIndex
      ) {
        const resourceIds = data
          .slice(
            Math.min(lastSelectedIndex + 1, resourceIndex),
            Math.max(lastSelectedIndex - 1, resourceIndex) + 1,
          )
          .map((r) => r.id);
        selection.selectMultiple(resourceIds);
      } else {
        selection.toggleSelection(resource.id);
        setLastSelectedIndex(resourceIndex);
      }
    },
    [data, selection, lastSelectedIndex],
  );

  useEffect(() => {
    setLastSelectedIndex(null);
  }, [data, selection?.allSelected]);

  if (renderEmptyState || showLoader) {
    return (
      <tbody className={styles.emptyStateBody}>
        <tr className={styles.emptyStateRow}>
          <td
            className={styles.emptyStateCell}
            style={{ width: `${tableContainerRef.current?.clientWidth}px` }}
          >
            {showLoader ? <CpLoader /> : renderEmptyState()}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody className={styles.tableBody}>
      {renderContextMenu && (
        <tr className={styles.hiddenRow}>
          <td className={styles.hiddenCell}>
            <CpContextDropdown
              ref={contextMenuRef}
              contentWidth="md"
              renderContent={(props) =>
                renderContextMenu({
                  ...props,
                  clickedResource: clickedResourceRef.current,
                })
              }
              onClose={() => setContextMenuResource(null)}
            />
          </td>
        </tr>
      )}
      {paddingTop > 0 && (
        <tr>
          <td style={{ height: `${paddingTop}px`, border: "none" }} />
        </tr>
      )}
      {rowVirtualizer.getVirtualItems().map((virtualRow) => {
        const resource = data[virtualRow.index];
        const rowIsDisabled = disabledResourceIds?.includes(resource.id);
        return (
          <MemoRow
            resource={resource}
            key={resource.id + "_" + virtualRow.index}
            schema={schema}
            orderedColumns={orderedColumns}
            openContextMenu={openContextMenu}
            contextMenuOpenOnRow={
              resource?.id && contextMenuResource?.id === resource.id
            }
            selection={selection}
            resourceIndex={virtualRow.index}
            onSelect={onSelect}
            verticalBorders={verticalBorders}
            rowIsDisabled={rowIsDisabled}
          />
        );
      })}
      {paddingBottom > 0 && (
        <tr>
          <td style={{ height: `${paddingBottom}px`, border: "none" }} />
        </tr>
      )}
    </tbody>
  );
}

function Row({
  rowIsDisabled,
  resource,
  schema,
  orderedColumns,
  openContextMenu,
  contextMenuOpenOnRow,
  selection,
  resourceIndex,
  onSelect,
  verticalBorders,
}) {
  const usingSelections = !!selection;
  return (
    <tr
      onContextMenu={(e) => {
        if (openContextMenu) {
          e.preventDefault();
          openContextMenu(e, resource);
        }
      }}
      className={m(styles.verticalBorder, verticalBorders)
        .m(styles.contextMenuHighlight, contextMenuOpenOnRow)
        .m(
          styles.selectedRow,
          rowIsDisabled ? false : selection?.isSelected(resource.id),
        )
        .m(styles.disableRow, rowIsDisabled)}
    >
      {selection && (
        <td className={styles.selectCell}>
          <SelectCell
            resource={resource}
            selection={selection}
            resourceIndex={resourceIndex}
            onSelect={onSelect}
            isDisabled={rowIsDisabled}
          />
        </td>
      )}
      <MemoDataRow
        orderedColumns={orderedColumns}
        schema={schema}
        resource={resource}
        usingSelections={usingSelections}
        rowIsDisabled={rowIsDisabled}
      />
    </tr>
  );
}
const MemoRow = React.memo(Row);

function DataRow({
  orderedColumns,
  schema,
  resource,
  usingSelections,
  rowIsDisabled,
}) {
  return orderedColumns.map((columnId) => {
    const columnSchema = schema[columnId];
    if (!columnSchema) return null;
    const CellComponent = columnSchema.cell.component;
    const value = resource[columnSchema.fieldId];
    const formattedValue = columnSchema.cell.formatValue
      ? columnSchema.cell.formatValue(value)
      : value;
    const cellIsEmpty =
      isNumber(formattedValue) || isBoolean(formattedValue)
        ? false
        : isEmpty(formattedValue);
    const showEmpty = cellIsEmpty && !CellComponent.overrideEmptyCell;

    const schemaProps = columnSchema.cell.props;
    const props =
      typeof schemaProps === "function"
        ? schemaProps({ resource, value })
        : schemaProps;

    return (
      <td
        key={columnId}
        className={m(styles.stickyCell, !!columnSchema.sticky)
          .m(`cp-text-${props?.align}`, props?.align)
          .a("cp-ellipsis")}
        style={
          !!columnSchema.sticky
            ? { left: usingSelections ? "4rem" : "0" }
            : null
        }
      >
        {showEmpty ? (
          <EmptyCell isDisabled={rowIsDisabled} />
        ) : (
          <CellComponent
            isDisabled={rowIsDisabled}
            resource={resource}
            columnId={columnId}
            columnSchema={columnSchema}
            value={formattedValue}
            {...props}
          />
        )}
      </td>
    );
  });
}
const MemoDataRow = React.memo(DataRow);
